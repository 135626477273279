import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import Select from "react-select";

import { cn } from "../../lib/helpers";
import slugify from "../../slugify";
import * as styles from "./menu.module.css";
import * as commonStyles from "../../styles/common.module.css";

const isBrowser = typeof window !== "undefined";

function Menu({ services = [], tags = [] }) {
  const [open, setOpen] = useState(false);
  const [services0pen, setServicesOpen] = useState(false);

  const openMenu = () => {
    if (!isBrowser) return;
    if (open) {
      document.body.style.overflow = "inherit";
      setOpen(!open);
    } else {
      document.body.style.overflow = "hidden";
      setOpen(!open);
    }
  };

  return (
    <header>
      <Link to={"/diagnostika-tcm/"}>
        <div className={cn(commonStyles.colorAccent, commonStyles.fontReleway, styles.alert)}>
          🔥 DIAGNOSTIKA
        </div>
      </Link>
      {/* <a
        title="Black Friday"
        target="_blank"
        href="https://shop.bbclinic.cz/p/valentynsky-poukaz-kiss-lips"
      >
        <div className={cn(commonStyles.colorAccent, commonStyles.fontReleway, styles.alert)}>
          💋 VALENTÝN
        </div>
      </a> */}
      <a
        title="BBClinic Shop"
        className={cn(commonStyles.colorAccent, commonStyles.fontReleway, styles.eshopButton)}
        target="_blank"
        href="https://shop.bbclinic.cz/"
      >
        SHOP
      </a>
      <button className={styles.button} onClick={() => openMenu()}>
        <div className={cn(styles.navIcon1, open && styles.open)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={cn(styles.menuText, open && styles.textOpen)}>MENU</div>
      </button>
      <div className={cn(styles.menuContainer, open && styles.containerOpen)}>
        <div className={styles.menuCenter}>
          <span>Beauty Body Clinic</span>
          <ul className={styles.menuList}>
            <li>
              <Link to={"/akce/"}>Akce</Link>
            </li>
            <li>
              <Link to={"/magazin/"}>Magazín</Link>
            </li>
            <li>
              <Link to={"/galerie/"}>Galerie</Link>
            </li>
            <li>
              <Link to={"/o-nas/"}>O nás</Link>
            </li>
            <li onClick={() => setServicesOpen(!services0pen)}>Naše služby</li>
            <li className={cn(styles.services, services0pen && styles.servicesOpen)}>
              <ul>
                {services.map((service, index) => {
                  if (service.disabled) {
                    return;
                  }
                  return (
                    <li key={`serviceLink_${index}/`}>
                      <Link to={`/${service?.slug?.current}`}>{service?.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <Link to={"/cenik/"}>Ceník</Link>
            </li>
            <li>
              <Link to={"/kontakt/"}>Kontakt</Link>
            </li>
            <li className={commonStyles.colorAccent}>
              <Link to={"/konzultace/"}>Konzultace</Link>
            </li>
          </ul>
          <a
            title="BBClinic Instagram"
            target="_blank"
            href="https://www.instagram.com/beautybodyclinicpraha/"
          >
            <div className={styles.follow}>
              Sledujte nás
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </div>
          </a>
          {tags.length > 0 && (
            <Select
              className={styles.search}
              placeholder={"Vyhledávání"}
              isClearable={true}
              isSearchable={true}
              color="red"
              name="color"
              options={tags.map((tag) => ({
                value: slugify(tag.title),
                label: tag.title,
              }))}
              onChange={(e) => {
                if (e.value === null) return;
                navigate(`/vyhledavani/${e.value}`);
              }}
              theme={(theme) => {
                return {
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary75: "#d7bc8a",
                    primary50: "#e1cda7",
                    primary25: "#F4E7D0",
                    primary: "#CDAC6D",
                  },
                };
              }}
            />
          )}
        </div>
      </div>
    </header>
  );
}

export default Menu;
